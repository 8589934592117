import { ClockCircleOutlined, FileDoneOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import liveIcon from '../../assets/images/live-icon.png';
import lineIcon from '../../assets/images/line-icon.png';
import telegramIcon from '../../assets/images/telegram-icon.png';
import { useHistory } from 'react-router-dom';
import { utils } from '../../services/utils';
import { lottoLink } from './lotto-link';


const { Text, Link, Title } = Typography;

type Props = {
    time: number,
    credit: number
}

const DetailSider: FC<Props> = ({ time, credit }) => {

    let history = useHistory();
    const [profile, setProfile] = useState({ nickname: localStorage.getItem("name") })
    const [role, setRole] = useState(localStorage.getItem("role"))

    const onLogout = () => {
        localStorage.clear();
        setTimeout(() => {
            history.push("/");
        }, 1500)
    }

    return (
        <>
            <div className="user-detail">
                <Space>
                    <ClockCircleOutlined style={{ fontSize: 20 }} />
                    <Text style={{ color: '#fff' }}>{moment(time).format("DD/MM/YYYY HH:mm:ss")}</Text>
                </Space>

                <div style={{ margin: '15px 5px' }}>
                    <Space size={15}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>ผู้ใช้งาน</Text>
                        <Text style={{ color: '#fff' }}>{profile.nickname}</Text>
                    </Space>
                    <br />
                    <Space size={30}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>ระดับ</Text>
                        <Text style={{ color: '#fff' }}>{role}</Text>
                    </Space>
                    <br />
                    <Space size={22}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>เครดิต</Text>
                        <Text style={{ color: '#fff' }}>{
                            role === 'header' || role === 'agent' ? utils.numberWithCommas(credit + "") : '$$$'
                        }</Text>
                    </Space>
                </div>

                <Button block style={{ backgroundColor: '#1e1e2d', color: 'white', borderColor: 'white', marginBottom: 10 }}><LockOutlined /> เปลี่ยนรหัสผ่าน</Button>
                <Button block style={{ backgroundColor: '#4547FF', color: 'white', borderColor: '#4547FF', marginBottom: 10 }} onClick={() => onLogout()}><LogoutOutlined /> ออกจากระบบ</Button>
            </div>
            <Divider style={{ backgroundColor: '#BDCCDB', margin: '5px 0px 10px 0px' }} />

            <div>
                <div className="ad">
                    <Space size={18}>
                        <FileDoneOutlined style={{ fontSize: 20 }} />
                        <Link href={lottoLink()} target="_blank" style={{ color: '#fff' }}>
                            ตรวจสอบผลรางวัล
                        </Link>
                    </Space>
                    <br />
                    {/* <Space>
                        <img src={liveIcon} style={{ width: 30, height: 20 }} alt="live lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            LIVE ผลหวย
                        </Link>
                    </Space> */}
                </div>

                {/* <div className="contact">
                    <Title level={5} style={{ color: '#fff' }}>ติดต่อเรา</Title>
                    <Divider style={{ backgroundColor: '#fc1f54', margin: '5px 0px 10px 0px' }} />
                    <Space style={{ marginBottom: 5 }}>
                        <img src={lineIcon} style={{ width: 20, height: 20 }} alt="line lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            @REALLOTTO
                        </Link>
                    </Space>
                    <br />
                    <Space>
                        <img src={telegramIcon} style={{ width: 20, height: 20 }} alt="telegram lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            @REALLOTTO
                        </Link>
                    </Space>
                </div> */}
            </div>
        </>
    )
}

export default DetailSider;
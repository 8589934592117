import { FC, useState, useEffect, useCallback } from "react";
import MainLayout from "../../components/main-layout/main-layout";
import { Button, Empty, Form, Input, Modal, Popconfirm, Select, Space, Spin, Table, Tag, Typography } from "antd";
import { LottoPermission, MemberType } from "../member/member.type";
import { useHistory } from "react-router-dom";
import { activeLottoPermission, createMember, deleteMember, getAllMembers, getLottoMaster, logout, updateCredit, updateMember } from "../../services/services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Notification from "../../components/notification/notification";
import { utils } from "../../services/utils";

const { Title } = Typography;
const { Option } = Select;

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};

const lottoPermissionColums: any = (onActiveLottoMaster: Function) => {
    const colums = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: LottoPermission, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'หวย',
            dataIndex: 'lottoMaster',
            key: 'lottoMaster',
            render: (text: string, record: LottoPermission, index: number) => {
                return <>{record.lottoMaster?.name}</>
            }
        },
        {
            title: 'สถานะเปิดใช้งาน',
            key: 'action',
            align: 'center',
            render: (text: string, record: LottoPermission, index: number) => (
                <Space size="middle">
                    <Popconfirm
                        placement="topLeft"
                        title={"ปรับการใช้งาน"}
                        okText={record.isActive === 'active' ? 'ปิดใช้งาน' : 'เปิดใช้งาน'}
                        onConfirm={() => onActiveLottoMaster(record)}
                        cancelText="ยกเลิก"
                    >
                        <Tag style={{ fontSize: 14, cursor: 'pointer' }} key={index} color={record.isActive === 'active' ? '#87d068' : '#f50'}>{record.isActive === 'active' ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return colums;
}

const memberColumns: any = (onEditMember: Function, onConfirmDeleteMember: Function, showLottoPermission: any, openAddCreditModal: Function) => {
    const columns = [
        {
            title: 'ลำดับ',
            dataIndex: 'id',
            key: 'id',
            // fixed: 'left',
            // width: 70,
            render: (text: string, record: MemberType, index: number) => {
                return <>{index + 1}</>
            }
        },
        {
            title: 'ชื่อเล่น',
            dataIndex: 'nickname',
            key: 'nickname',
            // fixed: 'left',
            // width: 100,
        },
        {
            title: 'ชื่อผู้ใช้ในระบบ',
            dataIndex: 'username',
            key: 'username',
            // width: 150,
        },
        {
            title: 'เครดิต (บาท)',
            dataIndex: 'credit',
            key: 'credit',
            align: 'right',
            render: (text: string, record: MemberType, index: number) => {
                return (
                    <Space>
                        {utils.numberWithCommas(text)}
                        <Button size="small" type="primary" danger onClick={() => openAddCreditModal(record)}>เติม</Button>
                    </Space>
                );
            }
        },
        {
            title: 'หุ้น',
            dataIndex: 'ownShare',
            key: 'ownShare',
            align: 'right',
            render: (text: string, record: MemberType, index: number) => {
                return text + "%";
            }
        },
        {
            title: 'หวยที่รับผิดชอบ',
            dataIndex: 'lottoPermission',
            key: 'lottoPermission',
            align: 'center',
            fixed: 'right',
            render: (text: string, record: MemberType, index: number) => (
                <Space size="middle">
                    <Button size="small" type="default" onClick={() => showLottoPermission(record)}>จัดการ</Button>
                </Space>
            ),
            // width: 150,
        },
        {
            title: 'จัดการ',
            key: 'action',
            align: 'center',
            fixed: 'right',
            render: (text: string, record: MemberType, index: number) => (
                <Space size="middle">
                    <Button size="small" type="primary" onClick={() => onEditMember(record)}>แก้ไข</Button>
                    <Button size="small" danger onClick={() => onConfirmDeleteMember(record)}>ลบ</Button>
                </Space>
            ),
        },
    ];

    return columns;
}


const Admin: FC = () => {

    let history = useHistory();
    // form
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [formCredit] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [members, setMembers] = useState<any>(null);
    const [lottoMasters, setLottoMasters] = useState<any>(null);
    const [memberSelected, setMemberSelected] = useState<any>(null);
    const [addMemberVisible, setAddMemberVisible] = useState(false);
    const [editMemberVisible, setEditMemberVisible] = useState(false);
    const [deleteMemberVisible, setDeleteMemberVisible] = useState(false);
    const [rankSelected, setRankSelected] = useState('');

    const [lottoPermissionVisible, setLottoPermissionVisible] = useState(false);
    const [lottoPermissions, setLottoPermissions] = useState<any>([]);
    const [addCreditMemberVisible, setAddCreditMemberVisible] = useState(false);

    const getMembers = useCallback(async () => {
        const response = await getAllMembers('filter=rank.id||$eq||1');
        setMembers(response.data);
    }, []);

    const getLottoMasters = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMasters(response.data);
    }, []);

    useEffect(() => {
        getMembers().then(() => setLoading(false)).catch(() => {
            setLoading(false);
            logout();
            return history.push('/login');
        });

        getLottoMasters().then(() => setLoading(false)).catch(() => {
            setLoading(false);
            logout();
            return history.push('/login');
        });

    }, [getMembers, getLottoMasters]);

    const onAddMember = async () => {
        formAdd.resetFields();
        setAddMemberVisible(true);
    }

    const onEditMember = async (member: MemberType) => {
        formEdit.resetFields();
        formEdit.setFieldsValue({
            nickname: member.nickname,
            username: member.username,
            rank: member.rank?.id + ""
        });
        setRankSelected(member.rank?.id + "");
        setMemberSelected(member);
        setEditMemberVisible(true);
    }

    const onConfirmDeleteMember = (member: MemberType) => {
        setMemberSelected(member);
        setDeleteMemberVisible(true);
    }

    const onConfirmAdd = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onSaveNewMember()
        });
    }

    const onSaveNewMember = async () => {
        setLoadingForm(true);
        formAdd.validateFields().then(async (value) => {
            let data = formAdd.getFieldsValue();
            data["rank"] = parseInt(data["rank"]);
            data["credit"] = parseInt(data["credit"]);
            data["ownShare"] = parseInt(data["ownShare"]);
            data["grant"] = 1;

            const response = await createMember(data);
            if (response.status === 201) {
                await getMembers();
                Notification('success', 'สร้างเฮดเดอร์สำเร็จ');
            } else {
                Notification('error', 'สร้างเฮดเดอร์ไม่สำเร็จ');
            }
            setAddMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            // console.log(reason);
            Notification('error', 'สร้างเฮดเดอร์ไม่สำเร็จ' + reason);
            setLoadingForm(false);
        });
    }

    const onChangeRank = (e: any) => {
        setRankSelected(e);
    }

    const membersData = () => {
        if (!members) return [];

        const data: MemberType[] = members['data'];
        return data;
    }

    const onConfirmEdit = () => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'ต้องการบันทึกข้อมูลใช่หรือไม่?',
            okText: 'บันทึก',
            cancelText: 'ยกเลิก',
            onOk: () => onEditSave()
        });
    }

    const onEditSave = async () => {
        setLoadingForm(true);
        formEdit.validateFields().then(async (value) => {
            const data = formEdit.getFieldsValue();

            if (data.password === "") delete data.password;
            if (data.rank) data.rank = parseInt(data.rank);
            if (data.ownShare) data.ownShare = parseInt(data.ownShare);

            const response = await updateMember(memberSelected.id, data);
            if (response.status === 200) {
                await getMembers();
                Notification('success', 'แก้ไขเฮดเดอร์สำเร็จ');
            } else {
                Notification('error', 'แก้ไขเฮดเดอร์ไม่สำเร็จ');
            }
            setEditMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'สร้างเฮดเดอร์ไม่สำเร็จ' + reason);
            setLoadingForm(false);
        });
    }

    const onDeleteMember = async () => {
        setLoadingForm(true);
        const response = await deleteMember(memberSelected.id);
        if (response.status === 200) {
            await getMembers();
            Notification('success', 'ลบเฮดเดอร์สำเร็จ');
        } else {
            Notification('error', 'ลบเฮดเดอร์ไม่สำเร็จ');
        }
        setDeleteMemberVisible(false);
        setLoadingForm(false);
    }


    // LottoPermission
    const showLottoPermission = (member: MemberType) => {
        setMemberSelected(member);
        setLottoPermissions(member.lottoPermissions);
        setLottoPermissionVisible(true);
    }

    const onActiveLottoMaster = async (lottoPermission: LottoPermission) => {
        setLoading(true);
        const status = lottoPermission.isActive === 'active' ? 'inactive' : 'active';
        const response = await activeLottoPermission(lottoPermission.id, status);
        if (response.status === 200) {
            let newLottoPermissions = [...lottoPermissions];
            const index = newLottoPermissions.findIndex((v: any) => v.id === lottoPermission.id);
            newLottoPermissions[index].isActive = status;
            setLottoPermissions(newLottoPermissions);

            Notification('success', status === 'active' ? 'เปิดใช้งานสำเร็จ' : 'ปิดใช้งานสำเร็จ');
        } else {
            Notification('error', 'เปลี่ยนการใช้งานไม่สำเร็จ');
        }
        setLoading(false);
    }

    const openAddCreditModal = (member: MemberType) => {
        formCredit.resetFields();
        setMemberSelected(member);
        setAddCreditMemberVisible(true);
    }

    const onUpdateCredit = async () => {
        setLoadingForm(true);
        formCredit.validateFields().then(async (value) => {
            const data = formCredit.getFieldsValue();

            const response = await updateCredit(memberSelected.id, parseInt(data.credit));
            if (response.status === 200) {
                await getMembers();

                Notification('success', 'เพิ่มเครดิตสำเร็จ');
            } else {
                Notification('error', 'เพิ่มเครดิตไม่สำเร็จ');
            }
            setAddCreditMemberVisible(false);
            setLoadingForm(false);
        }).catch((reason) => {
            Notification('error', 'เพิ่มเครดิตไม่สำเร็จ' + reason);
            setLoadingForm(false);
        });

    }

    return (
        <MainLayout menu="admin">
            <Spin spinning={loading}>
                <Modal
                    title={<Title level={5}>เพิ่มเฮดเดอร์</Title>}
                    maskClosable={false}
                    style={{ top: 25 }}
                    open={addMemberVisible}
                    onOk={() => onConfirmAdd()}
                    okText="บันทึก"
                    onCancel={() => setAddMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    <Spin spinning={loadingForm}>
                        <Form {...layout} form={formAdd}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill nickname'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อเล่น" />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                label="ชื่อผู้ใช้"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill username'
                                    },
                                ]}
                            >
                                <Input placeholder="ระบุชื่อผู้ใช้" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="รหัสผ่าน"
                                rules={[{ required: true }]}
                            >
                                <Input type={'password'} placeholder="รหัสผ่าน" />
                            </Form.Item>
                            <Form.Item name="rank" label="ตำแหน่ง" rules={[{ required: true }]}>
                                <Select
                                    placeholder="เลือกตำแหน่ง"
                                    onChange={(e) => onChangeRank(e)}
                                >
                                    <Option value="1">เฮดเดอร์</Option>
                                    {/* <Option value="2">เฮดเดอร์</Option> */}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="credit"
                                label="เครดิต"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill credit'
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="ระบุเงินเครดิต" />
                            </Form.Item>
                            <Form.Item
                                name="ownShare"
                                label="แบ่งหุ้น"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please fill share'
                                    },
                                ]}
                            >
                                <Input type="number" placeholder="ระบุหุ้นที่แบ่งเป็นเปอร์เซ็นต์" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title={<Title level={5}>แก้ไขข้อมูลเฮดเดอร์</Title>}
                    maskClosable={false}
                    style={{ top: 25 }}
                    open={editMemberVisible}
                    onOk={() => onConfirmEdit()}
                    okText="แก้ไขเฮดเดอร์"
                    onCancel={() => setEditMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    {memberSelected ?
                        <Spin spinning={loadingForm}>
                            <Form {...layout} form={formEdit}>
                                <Form.Item
                                    name="nickname"
                                    label="ชื่อเล่น"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill nickname'
                                        },
                                    ]}
                                >
                                    <Input placeholder="ระบุชื่อเล่น" />
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label="ชื่อผู้ใช้"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill username'
                                        },
                                    ]}
                                >
                                    <Input placeholder="ระบุชื่อผู้ใช้" />
                                </Form.Item>
                                <Form.Item name="rank" label="ตำแหน่ง" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="เลือกตำแหน่ง"
                                        onChange={(e) => onChangeRank(e)}
                                    >
                                        <Option value="1">เฮดเดอร์</Option>
                                        {/* <Option value="2">เฮดเดอร์</Option> */}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="รหัสผ่าน"
                                >
                                    <Input type={'password'} placeholder="กรอกรหัสผ่านเมื่อต้องการเปลี่ยน" />
                                </Form.Item>
                                <Form.Item
                                    name="ownShare"
                                    label="แบ่งหุ้น"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill share'
                                        },
                                    ]}
                                >
                                    <Input type="number" placeholder="ระบุหุ้นที่แบ่งเป็นเปอร์เซ็นต์" />
                                </Form.Item>
                            </Form>
                        </Spin>
                        : <></>}
                </Modal>
                <Modal
                    title={<Title level={5}>ลบเฮดเดอร์</Title>}
                    maskClosable={false}
                    style={{ top: 25 }}
                    open={deleteMemberVisible}
                    onOk={() => onDeleteMember()}
                    okText="ลบเฮดเดอร์"
                    onCancel={() => setDeleteMemberVisible(false)}
                    cancelText="ยกเลิก"
                    width={1000}
                >
                    <Spin spinning={loadingForm}>
                        <Title level={5}>ต้องการลบ {memberSelected ? memberSelected.nickname : ''} ใช่หรือไม่?</Title>
                    </Spin>
                </Modal>
                <Modal
                    title={<Title level={5}>เพิ่มเครดิต</Title>}
                    open={addCreditMemberVisible}
                    maskClosable={false}
                    onOk={() => onUpdateCredit()}
                    okText="ยันยืน"
                    onCancel={() => setAddCreditMemberVisible(false)}
                    cancelText="ยกเลิก"
                >
                    <Spin spinning={loadingForm}>
                        <Form form={formCredit}>
                            <Form.Item
                                name="credit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาระบุเครดิต'
                                    },
                                    {
                                        message: 'เครดิตห้ามต่ำกว่า 1',
                                        validator: (_, value) => {
                                            if (parseInt(value) > 0) return Promise.resolve();

                                            return Promise.reject('เครดิตห้ามต่ำกว่า 1');
                                        }
                                    }
                                ]}
                            >
                                <Input placeholder="ระบุเครดิตที่เติม" type="number" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal
                    title={<Title level={5}>หวยที่รับผิดชอบ</Title>}
                    maskClosable={false}
                    style={{ top: 25 }}
                    open={lottoPermissionVisible}
                    onOk={() => setLottoPermissionVisible(false)}
                    onCancel={() => setLottoPermissionVisible(false)}
                    okText="ปิด"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={1000}
                >
                    <Spin spinning={loading}>
                        <Title level={4}>ชื่อเล่นแอดมิน: {memberSelected?.nickname}</Title>
                        <Table style={{ marginTop: 10 }} size="small" columns={lottoPermissionColums(onActiveLottoMaster)} dataSource={lottoPermissions} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />
                    </Spin>
                </Modal>

                <Title level={4}>เฮดเดอร์</Title>
                <Button type="primary" onClick={() => onAddMember()}>เพิ่มเฮดเดอร์</Button>
                <Table style={{ marginTop: 10 }} size="small" columns={memberColumns(onEditMember, onConfirmDeleteMember, showLottoPermission, openAddCreditModal)} dataSource={membersData()} scroll={{ x: 1100 }} pagination={{ defaultPageSize: 50, position: ['bottomRight'] }} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} />
            </Spin>
        </MainLayout>
    )
}

export default Admin;
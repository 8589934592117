import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: `https://op8vg1apd5.execute-api.ap-southeast-1.amazonaws.com/prod`,
    withCredentials: false, // This is the default
    timeout: 60000
});

export const Profile = createAsyncThunk(
    "profile",
    async (_: void, { rejectWithValue }: any) => {
        try {
            const response = await apiClient.post('/member/profile', null, {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            });
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
) 
import { Button, Checkbox, DatePicker, Descriptions, Divider, Empty, Modal, Space, Spin, Table, Tag, Typography } from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import Notification from "../../components/notification/notification";
import { getLottoMaster, logout } from "../../services/services";
import { utils } from "../../services/utils";
import { BetType } from "../../types/bet.type";
import { LottoMaster, PeriodType } from "../../types/period.type";
import { useDispatch, useSelector } from "react-redux";
import { setDate, setLoading, setLottoMasterChecked, setSummary } from "../../stores/result.slice";
import { getMemberResult, getResultByDay } from "../../services/result.service";


const { Title } = Typography;
const { RangePicker } = DatePicker;

const renderPeriodColumns = (onShowPeriodDetail: Function) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>งวดที่</b>,
            dataIndex: 'dateAnnounce',
            key: 'dateAnnounce',
            width: 500
        },
        {
            title: <b>ยอดซื้อ</b>,
            dataIndex: 'buy',
            key: 'buy',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: "green" }}>{text}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#7cb305' }}>{utils.numberWithCommas(text)}</span>
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'pay',
            key: 'pay',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#d4380d' }}>{text}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                const sum = parseFloat(record.buySummary) - (parseFloat(record.paySummary) + parseFloat(record.commission));
                return <span style={{ fontSize: 16, color: '#c41d7f' }}>{utils.numberWithCommas(sum + "")}</span>
            }
        },
        {
            title: <b>รายการแทง</b>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return <Button type="link" onClick={() => onShowPeriodDetail(record)}>รายการแทง</Button>
            }
        },
    ]

    return columns;
}

const renderBillColumns = () => {
    const columns: ColumnsType<any> = [
        // {
        //     title: 'หมายเลข',
        //     dataIndex: 'numbers',
        //     key: 'numbers'
        // },
        // {
        //     title: 'การแทง',
        //     dataIndex: 'betDataMaster',
        //     key: 'betDataMaster'
        // },
        {
            title: <b>ประเภท @ หมายเลข</b>,
            dataIndex: 'numbersBetDataMaster',
            key: 'numbersBetDataMaster',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return record.betDataMaster + " @ " + record.numbers;
            }
        },
        {
            title: <b>ราคาแทง</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                return <span style={{ color: '#389e0d' }}>{text}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                return <span style={{ color: '#cf1322' }}>{text}</span>
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return record.status === 'lose' ? <span style={{ color: 'red' }}>แพ้</span> : <span style={{ color: 'green' }}>ชนะ = {record.earn}</span>;
            }
        },
        // {
        //     title: 'จ่าย',
        //     dataIndex: 'earn',
        //     key: 'earn',
        //     render: (text: string, record: any, index: number) => {  
        //         return record.status === 'lose' ? <span style={{ color: 'red' }}>{record.earn}</span> : <span style={{ color: 'green' }}>{record.earn}</span>;
        //     }
        // },
        {
            title: <b>หมายเหตุ</b>,
            dataIndex: 'detail',
            key: 'detail',
            align: 'right',
        },
        {
            title: <b>คนขาย</b>,
            dataIndex: 'member',
            key: 'member',
            align: 'center',
        },
    ];

    return columns;
}

const renderMemberResultColumns = (onShowMemberPeriodDetail: Function, onShowClient: Function, role: string | null) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return index + 1
            }
        },
        {
            title: <b>สมาชิก</b>,
            dataIndex: 'nickname',
            key: 'nickname',
            width: 200,
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return record.hasChild ? <Typography.Link onClick={() => onShowClient(record)}>{text}</Typography.Link> : text;
            }
        },
        {
            title: <b>ระดับ</b>,
            dataIndex: 'rank',
            key: 'rank',
            width: 100,
            render: (text: string, record: any, index: number) => {
                return <b>{text === 'header' ? 'เฮดเดอร์' : text === 'agent' ? 'เอเย่น' : 'เมมเบอร์'}</b>;
            }
        },
        {
            title: <b>ยอดทั้งหมด</b>,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: 'green' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#7cb305' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'pay',
            key: 'pay',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(text ? text : "0")}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                const sum = parseFloat(record.total) - (parseFloat(record.pay) + parseFloat(record.commission));
                return <span style={{ fontSize: 16, color: '#c41d7f' }}>{utils.numberWithCommas(sum ? sum + "" : "0")}</span>
            }
        },
    ];

    columns.push({
        title: <b>หุ้นสุทธิ</b>,
        dataIndex: 'share',
        key: 'share',
        align: 'right',
        render: (text: string, record: any, index: number) => {

            const total = ((record.total - record.pay) * (record.share / 100.00)) * 1.00;

            return total ? <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span> : <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas("0")}</span>;

            // if (record.pay) {
            //     if (record.pay !== 0) {
            //         const total = record.pay - (record.pay * (record.share / 100.00));
            //         return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>
            //     }

            //     const total = (record.total * (record.share / 100.00));
            //     return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>

            // }

            // return <span style={{ fontSize: 16, color: '#4547FF' }}>{utils.numberWithCommas("0")}</span>
        }
    },
        {
            title: <b>ส่วนแบ่งที่ได้รับ</b>,
            dataIndex: 'share',
            key: 'share',
            align: 'right',
            render: (text: string, record: any, index: number) => {

                const totalShare = ((record.total - record.pay) * (record.share / 100.00)) * 1.00;
                // total - (com + pay + share) 
                const total = record.total - (record.commission + record.pay + totalShare);

                return total ? <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span> : <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas("0")}</span>;

                // if (record.pay) {
                //     if (record.pay !== 0) {
                //         const total = record.commission + (record.pay - (record.pay * (record.share / 100.00)));
                //         return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>
                //     }

                //     const total = record.commission + (record.total * (record.share / 100.00));
                //     return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(total ? (total).toString() : "0")}</span>

                // }

                // return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas("0")}</span>
            }
        });


    columns.push({
        title: <b>รายการแทง</b>,
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text: string, record: any, index: number) => {
            return <Button type="link" onClick={() => onShowMemberPeriodDetail(record)}>รายการแทง</Button>
        }
    });

    return columns;
}

const Result: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch<any>();

    // const [date, setDate] = useState<any>(null);
    // const [loading, setLoading] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [curentPeriod, setCurentPeriod] = useState<any>(null);
    // const [dataSummary, setDataSummary] = useState<any>(null);
    // const [summary, setSummary] = useState<any>(null);
    // const [memberResult, setMemberResult] = useState<any>([]);
    const [lottoMaster, setLottoMaster] = useState([]);
    // const [lottoMasterChecked, setLottoMasterChecked] = useState<CheckboxValueType[]>([]);
    const { role, permissions } = utils.getRoleAndPermissions();

    const { dataSummary, summary, memberResult, date, loading, lottoMasterChecked } = useSelector((state: any) => {
        return {
            dataSummary: state.result.dataSummary,
            summary: state.result.summary,
            memberResult: state.result.memberResult,
            date: state.result.date,
            loading: state.result.loading,
            lottoMasterChecked: state.result.lottoMasterChecked
        }
    });

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data.data);
    }, []);

    useEffect(() => {
        dispatch(setLoading(true));
        getAllLottoMaster().then(() => dispatch(setLoading(false))).catch((v) => {
            dispatch(setLoading(false));
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster]);

    const renderDataSummary = (data: any) => {

        const rawData = [...data];
        const newdData = rawData.map((period: PeriodType, index: number) => {

            const createdDate = moment(period.createdAt).add(543, 'year').toDate();
            const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
            const announceDate = moment(moment(period.dateAnnounce).toDate()).format('เวลา HH:mm น.');

            let bets: any = [];
            let total = 0.00;
            let pay = 0.00;
            let commission = 0.00;
            period.bills?.forEach(bill => {
                total += bill.price;
                pay += bill.pay;
                bill.bets.map((v: BetType) => {
                    const newBet = { ...v };
                    newBet.detail = bill.detail;
                    newBet.member = bill.member.nickname;
                    newBet.commission = (newBet.betDataMaster.commissionRateMaster * 1.00) * (newBet.price / 100.00);
                    commission += newBet.commission;
                    return newBet;
                });
                bets.push(...bill.bets);
            });

            return {
                dateAnnounce: "หวย" + period.lottoMaster?.name + " " + periodDate + " (" + announceDate + ")",
                buy: utils.numberWithCommas(total + ""),
                buySummary: total,
                pay: utils.numberWithCommas(pay + ""),
                paySummary: pay,
                period,
                bets,
                commission
            };
        });

        return newdData;
    }

    const totalSummary = () => {

        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.buySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const commissionSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.commission;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const paySummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.paySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const netSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let total = 0.00;
        let pay = 0.00;
        let commission = 0.00;
        summary.forEach((v: any) => {
            total += v.buySummary;
            pay += v.paySummary;
            commission += v.commission;
        });

        let sum = total - (pay + commission);
        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const renderBet = () => {
        if (!curentPeriod) {
            return null;
        }

        return curentPeriod.bets.map((bet: any, index: number) => {
            return {
                numbers: bet.numbers,
                betDataMaster: bet.betDataMaster.name,
                price: bet.price,
                status: bet.status,
                earn: bet.earn,
                commission: bet.commission,
                detail: bet.detail ? bet.detail : '-',
                member: bet.member
            }
        });
    }

    const search = async (startDate: string, endDate: string) => {

        if (lottoMasterChecked.length === 0) return Notification('error', 'กรุณาเลือกตลาด');

        dispatch(setLoading(true));
        // moment(date[0]).format('YYYY-MM-DD 00:00:00');
        // moment(date[1]).format('YYYY-MM-DD 23:59:59')
        try {
            let filter = 'filter=dateOpen||$gte||' + startDate;
            filter += '&filter=dateOpen||$lte||' + endDate;
            filter += '&filter=bills.status||$eq||active';
            if (lottoMasterChecked.length > 0)
                filter += '&filter=lottoMaster.id||$in||' + lottoMasterChecked.join(",");
            // filter += '&join=bill&join=bill.bet'
            // let filter = 'filter=periods.dateOpen||$eq||' + date;
            const response = await dispatch(getResultByDay({ filter }));

            // filter data
            const data = response.payload.data.data;
            const summary = renderDataSummary(data);
            dispatch(setSummary(summary));

            // get member summary
            await searchMemberResult('between', startDate, endDate);
        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาดเล็กน้อย');
        }

        dispatch(setLoading(false));
    }

    const searchByTime = async (status: string) => {

        if (lottoMasterChecked.length === 0) {
            return Notification('error', 'กรุณาเลือกตลาด');
        }

        let dateByStatus = {
            startDate: null || '',
            endDate: null || ''
        }

        if (status === 'today') {
            dateByStatus.startDate = moment().format('YYYY-MM-DD 00:00:00');
            dateByStatus.endDate = moment().format('YYYY-MM-DD 23:59:59');

        } else {
            const date = utils.getWeek(status);
            dateByStatus.startDate = date.startDate;
            dateByStatus.endDate = date.endDate;
        }

        await search(dateByStatus.startDate, dateByStatus.endDate);
        dispatch(setDate([moment(dateByStatus.startDate), moment(dateByStatus.endDate)]));
    }

    const searchMemberResult = async (type: string, startDate: string, endDate: string) => {
        // moment(date[0]).format('YYYY-MM-DD 00:00:00')
        // moment(date[1]).format('YYYY-MM-DD 23:59:59')
        try {
            let filter = '?startDate=' + startDate;
            filter += '&endDate=' + endDate;
            filter += '&type=' + type;
            filter += '&id=' + localStorage.getItem("memberId");
            if (lottoMasterChecked.length > 0)
                filter += '&lottoMaster=' + lottoMasterChecked.join(",");

            await dispatch(getMemberResult({ filter }));
        } catch (error) {
            Notification('error', 'แจ้งเตือน', 'เกิดข้อผิดพลาดเล็กน้อย');
        }
    }

    const onShowPeriodDetail = (period: any) => {
        return history.push({
            pathname: '/result/bill',
            state: {
                period
            }
        })
    }

    const onShowClient = (record: any) => {
        return history.push({
            pathname: '/result/client',
            state: {
                data: record,
            }
        })
    }

    const renderFooterSummary = (dataSummary: any) => {

        if (dataSummary.length === 0) return <></>;

        // console.log(dataSummary);
        let price = 0;
        let commission = 0;
        let win = 0;
        let net = 0;

        dataSummary.forEach((v: any) => {
            price += v.buySummary;
            const commissionCal = v.commission
            commission += commissionCal;
            win += v.paySummary;

            net += v.buySummary - (v.commission + v.paySummary);
        });

        // const net = (win + commission) + price;

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                        <span style={{ fontWeight: 'bold' }}>รวม</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#389e0d' }}>{utils.numberWithCommas(price + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#7cb305' }}>{utils.numberWithCommas(commission + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(win + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#c41d7f' }}>{utils.numberWithCommas(net + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )
    }

    const renderMemberResultSummary = (memberSummary: any) => {
        if (memberSummary.length === 0) return <></>;

        // console.log(dataSummary);
        let total = 0;
        let commission = 0;
        let pay = 0;
        let share = 0;
        let netShare = 0;
        let net = 0;

        memberSummary.forEach((v: any) => {
            total += v.total;
            commission += v.commission;
            pay += v.pay;

            const shareCalculate = ((v.total - v.pay) * (v.share / 100.00)) * 1.00;
            share += shareCalculate;
            netShare += v.total - (v.commission + v.pay + shareCalculate);
            net += v.total - (v.commission + v.pay);
        });

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center" colSpan={3}>
                        <span style={{ fontWeight: 'bold' }}>รวม</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#389e0d' }}>{utils.numberWithCommas(total + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#7cb305' }}>{utils.numberWithCommas(commission + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(pay + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#c41d7f' }}>{utils.numberWithCommas(net + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#4547FF' }}>{utils.numberWithCommas(share + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(netShare + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )
    }

    const goReport = (location: any) => {
        const total = totalSummary();
        const commission = commissionSummary();
        const pay = paySummary();
        let query = "startDate=" + (!date ? '' : moment(date[0]).format('YYYY-MM-DD 07:00:00'));
        query += "&endDate=" + (!date ? '' : moment(date[1]).format('YYYY-MM-DD 07:00:00'));
        query += "&total=" + total;
        query += "&commission=" + commission;
        query += "&pay=" + pay;

        if (lottoMaster.length > 0) query += "&lottoMaster=" + lottoMasterChecked.join(",");
        return 'report?' + query;
    }

    const onShowMemberPeriodDetail = (data: any) => {
        return history.push({
            pathname: '/result/bill-member',
            state: {
                member: data
            }
        })
    }

    const onChangeLottoMaster = (e: CheckboxValueType[]) => {
        dispatch(setLottoMasterChecked(e));
    }

    const setLottoMasterOptions = () => {
        if (lottoMaster.length === 0) return lottoMaster;

        if (role === 'header') {
            if (permissions !== 'null') {
                const lottoMasterPermissions = utils.getLottoPermissions(true);
                const data = lottoMasterPermissions.map((v: LottoMaster) => {
                    const option: CheckboxOptionType = {
                        label: v["name"],
                        value: v["id"]
                    }
                    return option;
                });
                return data;
            }
        }

        const data = lottoMaster.map(v => {
            const option: CheckboxOptionType = {
                label: v["name"],
                value: v["id"]
            }
            return option;
        });
        return data;
    }

    return (
        <MainLayout menu="result">
            <Spin spinning={loading}>
                <Title level={4}>สรุปผล</Title>
                <Space>
                    <RangePicker onChange={(e: any) => dispatch(setDate(e))} placeholder={["จากวันที่", "ถึงวันที่"]} value={date} />
                    <Button type="primary" disabled={!date ? true : false} onClick={() => search(date ? moment(date[0]).format('YYYY-MM-DD 00:00:00') : '', date ? moment(date[1]).format('YYYY-MM-DD 23:59:59') : '')}>ค้นหา</Button>
                    <Button type="primary" onClick={() => searchByTime('today')}>วันนี้</Button>
                    <Button type="primary" onClick={() => searchByTime('current-week')}>สัปดาห์นี้</Button>
                    <Button type="primary" onClick={() => searchByTime('last-week')}>สัปดาห์ที่แล้ว</Button>
                </Space>
                <Divider className="divider-inside" />
                <Space>
                    <Title level={4}>เลือกตลาด</Title>
                    <Checkbox.Group value={lottoMasterChecked} style={{ fontSize: 14 }} options={setLottoMasterOptions()} onChange={(e) => onChangeLottoMaster(e)} />
                </Space>
                <Divider className="divider-inside" />
                <Descriptions column={3}>
                    <Descriptions.Item label={<Typography.Text strong>จากวันที่</Typography.Text>}>{date ? moment(date[0]).add(543, 'year').format('DD MMMM YYYY') : "-"}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ถึงวันที่</Typography.Text>}>{date ? moment(date[1]).add(543, 'year').format('DD MMMM YYYY') : "-"}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ดูแบบละเอียด</Typography.Text>}>
                        <Link to={(location) => goReport(location)}>คลิกที่นี่</Link>
                    </Descriptions.Item>
                </Descriptions>
                {role === 'super-admin' ?
                    <Descriptions column={4}>
                        <Descriptions.Item label={<Typography.Text strong>ยอดเงินรวม</Typography.Text>}><Tag color={'green'}>{totalSummary()}</Tag></Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>คอมมิชชั่นรวม</Typography.Text>}><Tag color={'lime'}>{commissionSummary()}</Tag></Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>ยอดจ่ายรวม</Typography.Text>}><Tag color={'red'}>{paySummary()}</Tag></Descriptions.Item>
                        <Descriptions.Item label={<Typography.Text strong>คงเหลือ</Typography.Text>}><Tag color={'magenta'}>{netSummary()}</Tag></Descriptions.Item>
                    </Descriptions> : <></>}
                <Divider className="divider-inside" />
                <Title level={4}>สรุปยอดแบบรายสมาชิก</Title>
                <Table size="small" columns={renderMemberResultColumns(onShowMemberPeriodDetail, onShowClient, role)} dataSource={memberResult} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={{ defaultPageSize: 100 }} summary={(memberResult: any) => renderMemberResultSummary(memberResult)} />
                <Divider className="divider-inside" />

                {role === 'super-admin' ?
                    <>
                        <Title level={4}>สรุปยอดแบบรายงวด</Title>
                        <Table size="small" columns={renderPeriodColumns(onShowPeriodDetail)} dataSource={summary} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={{ defaultPageSize: 50 }} summary={(dataSummary: any) => renderFooterSummary(dataSummary)} />
                    </> : <></>}
                <Divider className="divider-inside" />
                <Modal
                    title={<Title level={5}>ข้อมูลการแทง</Title>}
                    style={{ top: 25 }}
                    open={showDetailModal}
                    okText="ปิด"
                    onOk={() => setShowDetailModal(false)}
                    onCancel={() => setShowDetailModal(false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={1000}
                >
                    <Table size="small" columns={renderBillColumns()} dataSource={renderBet()} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false} />
                </Modal>
            </Spin>
        </MainLayout>
    );
}

export default Result;